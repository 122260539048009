$(function() {
  if ( $("#events").length ) {
    var limit = $("#events").data("events-limit");
    if (!limit) {
      limit = 0;
    }

    $.getScript("/api/events?limit=" + limit);
  }
});
